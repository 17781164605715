/* eslint-disable vue/one-component-per-file */
import { createApp } from 'vue';
import { AccountLink } from '@rei/account-flyout';
import { getUser } from '@rei/session';
import AdobeTarget from '../shared/utils/adobe-target';
import AccountNavComponent from './components/AccountNavComponent.vue';
import AccountNavMobileComponent from './components/AccountNavMobileComponent.vue';
import AccountLinkFragment from './components/AccountLinkFragment.vue';
import LoadingFragment from './components/LoadingFragment.vue';
import AccountSidebarComponent from './components/AccountSidebarComponent.vue';
import SignInNudgeComponent from './components/SignInNudgeComponent.vue';
import RewardsAPI from './APIs/rewardsAPI';

const gNav = document.querySelector('.gnav');
const isWhiteNav = gNav !== null;

let isAccountMounted = false;
let isSignInNudgeMounted = false;

let user = null;
let signInCreateAccountExperience = 'control';
let sidebarCobrandExperience = 'control';

if (isWhiteNav) {
  /* This is a default loading fragment that will display first and
    visually communicate to the user that something related to their
    account login is still pending.
  */
  createApp(LoadingFragment).mount('[data-ui="login-module-greeting"]');

  user = getUser()
    .then(async (userObj) => {
      user = userObj;
      const accountListItem = document.querySelector(
        '[data-ui="login-module-greeting-account-link"]',
      );
      if (accountListItem.hasAttribute('data-visible')) {
        accountListItem.removeAttribute('data-visible');
      }

      let notifications = null;
      let showExpiringRewardsNotifications = false;

      const signInCreateAccountTest = await AdobeTarget.get('APEX3106');
      if (signInCreateAccountTest) {
        signInCreateAccountExperience = signInCreateAccountTest;
      }

      if (user && user.isLoggedIn) {
        if (user.membership) {
          const sidebarCobrandTest = await AdobeTarget.get('APEX3661');

          if (sidebarCobrandTest) {
            sidebarCobrandExperience = sidebarCobrandTest;
          }

          const rewardsNotificationsTest = await AdobeTarget.get('APEX4192');

          if (rewardsNotificationsTest) {
            const rewards = await RewardsAPI.getMemberRewards();
            const expiringRewards = rewards?.lineItemList?.find(
              (e) => e.expiringSoon && e.amount > 0 && e.expirationDate,
            );
            if (expiringRewards) {
              window?.metrics?.link({ eligibleTests: 'Topnav_ExpiringRewards' });
              if (
                rewardsNotificationsTest !== 'control' &&
                !localStorage.getItem('flyoutNotificationSeen')
              ) {
                showExpiringRewardsNotifications = true;
                notifications = {
                  expiringRewards: { expirationDate: expiringRewards.expirationDate },
                };
              }
            }
          }
        }

        createApp(AccountNavComponent, {
          user,
          notifications,
        }).mount('[data-ui="login-module-greeting-account-link"]');

        createApp(AccountNavMobileComponent, {
          user,
        }).mount('li[data-js="mobile-nav-login-link"]');

        const sentMemberViewMetric = sessionStorage.getItem('memberViewSent');

        if (window && window.metrics && user.membership && !sentMemberViewMetric) {
          window.metrics.link({
            siteId: 'global-sign-in',
            checkoutRewardsTotal: user.membership.dividendBalance || 0,
          });
          sessionStorage.setItem('memberViewSent', 'true');
        }
      } else {
        createApp(AccountLinkFragment, {
          signInCreateAccountExperience,
        }).mount('[data-ui="login-module-greeting-account-link"]');
      }

      const accountToggleButton = document.querySelector(
        "[data-ui='site-header-action-local-account']",
      );
      const navbarWrapper = document.querySelector("[data-ui='site-navigation']");

      // Mount account and set event listeners, this function will only be executed if the account
      // button is hover or focused. Otherwise there is no need to mount component
      const mountAccount = () => {
        isAccountMounted = true;
        // Insert <li> element next to account toggle button to render Account component
        accountToggleButton.parentNode.insertAdjacentHTML(
          'afterEnd',
          `<li class="account-dropdown ${
            isWhiteNav && 'account-dropdown--white-nav'
          }"><div id="account-placement"></div></li>`,
        );
        createApp(AccountSidebarComponent, {
          user,
          sidebarCobrandExperience,
          showExpiringRewardsNotifications,
          notifications,
        }).mount('#account-placement');
      };

      const signInLink = document.querySelector("[data-ui='login-module-greeting']");
      const mountSignInNudge = () => {
        isSignInNudgeMounted = true;

        signInLink.insertAdjacentHTML(
          'afterEnd',
          `<li class="sign-in-nudge-dropdown ${
            isWhiteNav && 'sign-in-nudge--white-nav'
          }"><div id="sign-in-nudge-placement"></div></li>`,
        );

        createApp(SignInNudgeComponent, {
          user,
          signInCreateAccountExperience,
        }).mount('#sign-in-nudge-placement');
      };

      if (accountToggleButton && navbarWrapper && isWhiteNav) {
        ['focus', 'mouseenter'].forEach((eventName) =>
          accountToggleButton.addEventListener(
            eventName,
            () => {
              if (!isAccountMounted) {
                mountAccount();
              }
            },
            {
              once: true,
            },
          ),
        );
        if (!isAccountMounted) {
          mountAccount();
        }
      }

      if (signInLink && navbarWrapper && isWhiteNav) {
        setTimeout(() => {
          if (!isSignInNudgeMounted) {
            mountSignInNudge();
          }
        }, 2000);
      }
    })
    .catch(() => {
      createApp(AccountLink).mount('[data-ui="login-module-greeting"]');
    });
}
